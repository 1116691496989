import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Header() {
  const [navBarHeight, setNavBarHeight] = useState(0);
  const [sidePanelWidth, setSidePanelWidth] = useState("-355px");

  // Similar to componentDidMount.
  useEffect(() => {
    // Set the count State Value to Seven.
    setNavBarHeight(0);
    setSidePanelWidth("-355px");
  }, []);

  return (
    <div>
      {/* ======== 1.1. header section ======== */}
      <header>
        <nav className="container navbar navbar-expand-lg">
          <div className="container-fluid">
            {/* site logo */}
            <Link to="/" className="nav-logo p-0">
              <img src="assets/images/Logo.png" alt="logo" />
            </Link>
            {/* navigation button  */}
            <button
              className="navbar-toggle"
              onClick={() => {
                setNavBarHeight(280);
              }}
              type="button"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="fa-solid fa-bars"></i>
            </button>
            {/* navigation bar manu  */}
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav d-flex justify-content-center align-items-center gap-lg-2 gap-md-2 gap-sm-2 gap-2 mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link to="/intelligo" className="nav-link">
                    Intelligo
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/resources" className="nav-link">
                    Resources
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/about" className="nav-link">
                    About
                  </Link>
                </li>
                <li
                  className="nav-item"
                  onClick={() => {
                    setSidePanelWidth("0px");
                  }}
                >
                  <Link to="/resources" className="nav-link">
                    <i className="fa-sharp fa-solid fa-bars"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {/*============ navigation left slidebar================*/}
        <aside>
          <div
            id="mySidenav"
            className="sidenav"
            style={{ width: navBarHeight }}
          >
            <div className="side-nav-logo d-flex justify-content-between align-items-center ps-4 pe-3">
              <figure className="navbar-brand">
                <Link to="/" className="nav-link">
                  <img
                    src="assets/images/Logo.png"
                    alt="img"
                    className="nav-logo"
                  />
                </Link>
              </figure>
              <div
                className="closebtn"
                onClick={() => {
                  setNavBarHeight(0);
                }}
              >
                <i className="fa-solid fa-square-xmark side-bar-close-btn"></i>
              </div>
            </div>
            <ul>
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/intelligo" className="nav-link">
                  Intelligo
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/resources" className="nav-link">
                  Resources
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link">
                  About
                </Link>
              </li>
            </ul>
          </div>
        </aside>
        <section className="right-sidbar" id="right_side" style={{ right: sidePanelWidth }}>
          <div className="d-flex justify-content-between align-items-center">
            {/* site logo */}
            <Link to="/" className="p-0">
              <img src="assets/images/Logo.png" alt="logo" />
            </Link>
            <button
              className="close_right_sidebar fa-solid fa-xmark"
              onClick={() => {
                setSidePanelWidth("-355px");
              }}
            ></button>
          </div>
          <p className="mt-4 pb-3">
            Data Intelligence platform and product that puts your data to
            business insight journey at the frontier.
          </p>
          <a href="{{ route('product') }}" className="btn-hover1">
            Discover More
          </a>
          <hr />
          <h5 className="mt-4 mb-4">Connected details:</h5>
          <ul className="d-flex flex-column gap-3">
            <li>
              <Link to="/">
                {" "}
                <i className="fa-solid fa-phone"></i>
              </Link>

              <Link to="/">info@intelligo.com</Link>
            </li>
            <li>
              <Link to="/">
                <i className="fa-solid fa-envelope"></i>
              </Link>
              <Link to="/">(219) 214-6890</Link>
            </li>
          </ul>
          <span className="d-flex gap-4 mt-4">
            <Link to="/" className="p-0">
              <i className="fa-brands fa-facebook"></i>
            </Link>
            <Link to="/" className="p-0">
              <i className="fa-brands fa-instagram"></i>
            </Link>
            <Link to="/" className="p-0">
              <i className="fa-brands fa-twitter"></i>
            </Link>
          </span>
        </section>
      </header>
      {/* ======== End of 1.1. header section ======== */}
    </div>
  );
}

export default Header;
