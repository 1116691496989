// import logo from "./logo.svg";
import "./App.css";

import React, { useEffect } from "react";

import About from "./pages/about";
import Home from "./pages/home";
import Intelligo from "./pages/intelligo";
import Resources from "./pages/resources";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Governance from "./pages/details/governance";
import Observability from "./pages/details/observability";
import Orchestration from "./pages/details/orchestration";
import Transformation from "./pages/details/transformation";

import Aos from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 1200,
    });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/intelligo" element={<Intelligo />} />
        <Route path="/resources" element={<Resources />} />

        <Route path="/governance" element={<Governance />} />
        <Route path="/observability" element={<Observability />} />
        <Route path="/orchestration" element={<Orchestration />} />
        <Route path="/transformation" element={<Transformation />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
