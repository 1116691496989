import React from "react";
import Header from "./../../layout/header";
import Footer from "./../../layout/footer";

function Observability() {
  return (
    <div>
      <div className="site-wrapper">
        <div className="first_nav_hero_about">
          <Header></Header>
          {/* ======== 1.2. hero section ======== */}
          <section className="core">
            <div className="container">
              <h2 className="text-center">Observability</h2>
              <p className="core-p">The art of understanding data health.</p>
            </div>
          </section>
          {/* ======== End of 1.2. hero section ======== */}
        </div>

        {/* ======== 1.7. services section ========  */}
        <section className="services">
          <div className="container">
            <div class="row  justify-content-center mb-5">
              <div
                data-aos="fade-up"
                class="col-lg-6 col-md-6 d-flex align-items-center justify-content-center aos-init aos-animate"
              >
                <div class=" gateway-bg-img mt-5 ">
                  <figure>
                    <img src="assets/images/index/pricinge.png" alt="img" />
                  </figure>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 justify-content-center">
                <h3 class="mb-4 mt-4">What is Data Observability?</h3>
                <p>
                  The ability to understand the health of organization’s data at
                  rest, data in motion, data usage and data infrastructure by
                  continuously monitoring, tracking, alerting, analyzing,
                  troubleshooting, and recommending the corrective actions to
                  prevent firefighting.
                </p>
                <p className="text-lg-start text-md-start text-sm-center text-center mt-lg-2 mt-md-2 mt-sm-2 mt-2 pb-4">
                  Observability implemented correctly aims to improve
                  reliability of modern data ecosystems.
                </p>
              </div>
            </div>

            <div class="fin-card aos-init mb-4">
              <h3 class="mb-4">What business value it brings? </h3>
              <ul class="list-w mt-4">
                <li>Improved data quality</li>
                <li>Foster Trust in data and modern data ecosystems</li>
                <li>Data reliability</li>
                <li>Risk mitigation</li>
              </ul>
            </div>

            <div class="fin-card aos-init mb-4">
              <h3 class="mb-4">
                Should you buy it? Signs you need a data observability solution.
              </h3>
              <ul class="list-w mt-4">
                <li>Your data platform has recently migrated to cloud.</li>
                <li>
                  Your data stack is scaling with more data sources, more
                  tables, and more complexity. (Lots of ETL jobs and complex
                  data ecosystems)
                </li>
                <li>Your data team is growing.</li>
                <li>
                  Your team is spending at least 30% of their time firefighting
                  data quality issues.
                </li>
                <li>
                  Your team has more data consumers than you did 1 year ago.
                </li>
                <li>Your company is moving to self-service analytics model.</li>
                <li>Data is a key part of business decision making.</li>
              </ul>
            </div>

            <div class="fin-card aos-init mb-4">
              <h3 class="mb-4">
                What are the limitations we have seen in our competitors’
                offerings?
              </h3>
              <p>
                Data orchestration enables businesses to break down data silos,
                ensures business continuity, enforces regulatory compliance, and
                not only unifies data movements across organization but in an
                optimized and automated way. Ultimately enabling organization to
                focus on high-value tasks.
              </p>
              <ul class="list-w mt-4">
                <li>
                  Data quality is your priority, or your data platform has data
                  quality issues.
                </li>
                <li>
                  Your organization is integrating data from legacy system with
                  modern data stacks
                </li>
                <li>Your data volumes continue to grow exponentially</li>
                <li>
                  Your data stack is scaling with more data sources with
                  interdependencies, and more complexity. (Lots of ETL jobs and
                  complex data ecosystems)
                </li>
                <li>
                  Your team is spending at least 30% of their time firefighting
                  data related failures.
                </li>
                <li>Data is a key part of business decision making.</li>
              </ul>
            </div>

            <div class="fin-card aos-init mb-4">
              <h3 class="mb-4">
                What are the limitations we have seen in our competitors’
                offerings?
              </h3>
              <ul class="list-w mt-4">
                <li>Monitoring Vs Observability.</li>
                <li>Focuses only on Data at rest and not data in motions.</li>
                <li>Target Tech only and Fall shorts on End users.</li>
                <li>No visibility on Cost and Financial impacts.</li>
              </ul>
            </div>

            <div class="fin-card aos-init mb-4">
              <h3 class="mb-4">What are our Observability offerings?</h3>
              <p>Our solution observes and recommends:</p>
              <ul class="list-w mt-4">
                <li>
                  Data quality issues with completeness, accuracy, timeliness,
                  anomalies, uniqueness
                </li>
                <li>
                  Changes in Schema, volume, performance, frequency, time while
                  data-in-motion
                </li>
                <li>Your data volumes continue to grow exponentially</li>
                <li>
                  Logs and metrics around performance, compute, storage, and
                  network
                </li>
                <li>
                  Changes in metadata of ownership, usage with who, when, why,
                  and how often.
                </li>
                <li>
                  Financial impact with downtime, SLA, resource, and capacity
                  planning.
                </li>
              </ul>
            </div>
          </div>
        </section>
        {/* ======== End of 1.7. services section ========  */}
        <Footer></Footer>
      </div>
    </div>
  );
}

export default Observability;
