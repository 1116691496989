import React from "react";
import Header from "./../layout/header";
import Footer from "./../layout/footer";
import { Link } from "react-router-dom";

function Resources() {
  return (
    <div>
      <div className="site-wrapper">
        <div className="first_nav_hero_about">
          <Header></Header>
          {/* ======== 1.2. hero section ======== */}
          <section className="core">
            <div className="container">
              <h2 className="text-center">CORE FEATURES</h2>
              <p className="core-p">
                Low code platform that seamlessly connect with leading cloud
                providers, ensuring compatibility and flexibility for
                infrastructure needs.
              </p>
              <div className="row d-flex gap-md-0 gap-sm-5 gap-5">
                <div className="col-lg-4 col-md-4 d-flex flex-column gap-3 justify-content-center">
                  <Link to="/orchestration" className="nav-link">
                    <div className="core-card">
                      <h5>Orchestration</h5>
                      <p>
                        Ensuring the data is handled effectively at every stage
                        of the data journey.
                      </p>
                    </div>
                  </Link>
                  <Link to="/transformation" className="nav-link">
                    <div className="core-card">
                      <h5>Transformation </h5>
                      <p>
                        Assuring data is accurate, consistent, and ready for
                        analysis, leading to better insights and decision
                        maings.
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4 col-md-4 d-flex justify-content-center align-items-center">
                  <figure>
                    <img src="assets/images/feauter/mobile.png" alt="img" />
                  </figure>
                </div>
                <div className="col-lg-4 col-md-4 d-flex flex-column gap-3 justify-content-center">
                  <Link to="/observability" className="nav-link">
                    <div className="core-card1">
                      <h5>Observability</h5>
                      <p>
                        Ensure the data is accurate, reliable, and available
                        throughout lifecycle.
                      </p>
                    </div>
                  </Link>
                  <Link to="/governance" className="nav-link">
                    <div className="core-card1">
                      <h5>Governance</h5>
                      <p>
                        Ensuring data quality, security, accessibility, and
                        compliance with regulations.
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </section>
          {/* ======== End of 1.2. hero section ======== */}
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default Resources;
