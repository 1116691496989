import React from "react";
import Header from "./../layout/header";
import Footer from "./../layout/footer";
import { Link } from "react-router-dom";

function Intelligo() {
  return (
    <div>
      <div className="site-wrapper">
        <div className="first_nav_hero_about">
          <Header></Header>

          {/* ======== 1.2. hero section ======== */}
          <section className="feature-hero">
            <div className="container">
              <h1 className="text-center">Meet Intelligo</h1>
              <p className="text-center">
                Data Intelligence for all of us, whether you are starting your
                AI journey or <br />
                you want to make your business a competitive differentiator,
                Intelligo is here to help.{" "}
              </p>
              <div className="position-relative">
                <div className="featur-video">
                  <Link to="/orchestration" className="video-play-button">
                    <span className="fa-solid fa-play"></span>
                  </Link>
                </div>
                <figure className="feature-img">
                  <img src="assets/images/feauter/feature-girl.png" alt="img" />
                </figure>
                <figure className="feature-img2">
                  <img src="assets/images/feauter/feature-rect.png" alt="img" />
                </figure>
              </div>
            </div>
          </section>
          {/* ======== 1.2. hero section ======== */}
        </div>

        {/* ======== 1.6. gateway section ========  */}
        <section class="gateway">
          <div class="container">
            <div class="row gap-lg-0 gap-md-0 gap-sm-4 gap-4">
              <div class="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                <div class="gateway-bg-img mt-5">
                  <figure>
                    <img
                      src="assets/images/index/gateway-1.png"
                      alt="gate_img1"
                      class="moving"
                    />
                  </figure>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 text-md-start text-sm-center text-center">
                <h2>Intelligo capabilities</h2>
                <p class="pt-lg-4 pt-md-3 pt-sm-2 pt-2">
                  Intelligo's 3Cs enable your business decision making
                  capabilities and guarantees a better future for you and your
                  business.
                </p>
                <div class="gate mt-md-3 mt-sm-0 mt-4 d-flex flex-md-row flex-sm-column flex-column align-items-center">
                  <figure class="d-flex align-items-center">
                    <img
                      src="assets/images/icon/gate-icon1.png"
                      alt="gate-img1"
                    />
                  </figure>
                  <div class="account-text ms-3">
                    <h5 class="pb-2">Connected Insights</h5>
                    <p class="p-f-s">
                      <ul class="list-w mt-4">
                        <li>
                          It's low-code composable feature sets collects,
                          process, transform your data and optimize outcomes
                          that are more personalized and also more inclusive.{" "}
                        </li>
                        <li>
                          ○ It's orchestration and observability feature sets
                          support data-driven decision making auditable and
                          explainable for others.
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
                <div class="gate d-flex mt-4 flex-md-row flex-sm-column flex-column align-items-center">
                  <figure class="d-flex align-items-center">
                    <img
                      src="assets/images/icon/gate-icon2.png"
                      alt="gate-img2"
                    />
                  </figure>
                  <div class="ms-3">
                    <h5 class="pb-2">Contextual Analytics</h5>
                    <p class="p-f-s">
                      <ul class="list-w mt-4">
                        <li>
                          With wide variety of data and more advanced modeling,
                          your data to decision making is more accurate and
                          depicts your business's complex reality
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
                <div class="gate d-flex mt-4 flex-md-row flex-sm-column flex-column align-items-center">
                  <figure class="d-flex align-items-center">
                    <img
                      src="assets/images/icon/gate-icon3.png"
                      alt="gate-img3"
                    />
                  </figure>
                  <div class="ms-3">
                    <h5 class="pb-2">Continuous Intelligence</h5>
                    <p class="p-f-s">
                      <ul class="list-w mt-4">
                        <li>
                          Its low-code set up captures larger window of
                          opportunity from your data and automation can scale
                          number of decisions your business can make.{" "}
                        </li>
                        <li>
                          It's near real-time contextual data workflows builds
                          adaptive decision-making that responds as the business
                          context changes.
                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ======== End of 1.6. gateway section ========  */}
        <section class="visa mt-md-5 mt-sm-5 mt-5 mb-0 feature-hero">
          <div class="container text-center">
            <h2 class="container text-center">Intelligo family</h2>
            <p className="text-center">
              We meet you where you are in your AI journey to offer you best
              combination of speed to market and scalable.
            </p>

            <div class="all-boxs">
              <div class="row gy-4 gy-md-4 gy-lg-0">
                <div class="col-md-12 col-lg-12 box1">
                  <div class="box">
                    <div class="inner-box">
                      <span class="caption-h">
                        High performing &amp; at Scale
                      </span>
                      <h3>Unilyticx</h3>
                      <p style={{ color: "black" }}>
                        Our unique and highest offerings to support your end to
                        end modern data eco system needs (Ingestion, processing,
                        orchestration, observability and data governance).
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-8 box2">
                  <div class="box">
                    <div class="inner-box">
                      <span class="caption-h">
                        Efficient &amp; Comprehensive
                      </span>
                      <h3>Simplyticx</h3>
                      <p style={{ color: "black" }}>
                        Our best combination of speed and intelligence framework
                        which supports not only your data ingestions needs but
                        supports much required orchestration and observability
                        support.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 col-lg-4 box3">
                  <div class="box">
                    <div class="inner-box">
                      <span class="caption-h">
                        Lightning fast &amp; flexible
                      </span>
                      <h3>Bolticx</h3>
                      <p style={{ color: "black" }}>
                        Our fastest framework which expedite your data
                        ingestion, processing and curating journey with industry
                        leading speed.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer></Footer>
      </div>
    </div>
  );
}

export default Intelligo;
