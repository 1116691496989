import React from "react";
import Header from "./../../layout/header";
import Footer from "./../../layout/footer";

function Orchestration() {
  return (
    <div>
      <div className="site-wrapper">
        <div className="first_nav_hero_about">
          <Header></Header>
          {/* ======== 1.2. hero section ======== */}
          <section class="core">
            <div class="container">
              <h2 class="text-center">Orchestration</h2>
              <p class="core-p">The art of understanding data health.</p>
            </div>
          </section>
          {/* ======== End of 1.2. hero section ======== */}
        </div>

        <section className="services">
          <div className="container">
            <div class="row  justify-content-center mb-5">
              <div
                data-aos="fade-up"
                class="col-lg-6 col-md-6 d-flex align-items-center justify-content-center aos-init aos-animate"
              >
                <div class=" gateway-bg-img mt-5 ">
                  <figure>
                    <img src="assets/images/index/pricinge.png" alt="img" />
                  </figure>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 justify-content-center">
                <h3 class="mb-4 mt-4">What is Data Orchestration? </h3>
                <p>
                  Data orchestration is the strategic process of harmonizing and
                  optimizing data workflows across an organization's ecosystem,
                  and facilitating all activities required to convert raw data
                  into insights.
                </p>
              </div>
            </div>

            <div class="fin-card aos-init mb-4">
              <h3 class="mb-4">What business value it brings? </h3>
              <h5 class="mb-2">Operational Resilience</h5>
              <p>
                By keeping an eye on data workflows and proactively adapting as
                per network complexities, resource availability, and data
                dependencies to minimize the disruptions
              </p>
              <h5 class="mb-2 mt-4">Breaking down Silos</h5>
              <p>
                Help integrate and unify data from disparate sources and
                providing a holistic view of organization’s data assets
              </p>
              <h5 class="mb-2 mt-4">Optimizing Process</h5>
              <p>
                Manual data processes are time-consuming and prone to error.
                Automated data orchestration not only streamline data workflows
                but optimize them as well.
              </p>
              <h5 class="mb-2 mt-4">Enforcing Regulatory Compliance</h5>
              <p>
                By ensuring that data tasks are executed in correct order with
                right ownership and that dependencies are properly managed and
                documented.
              </p>
            </div>
            <div class="fin-card aos-init mb-4">
              <h3 class="mb-4">
                Should you buy it? Signs you need a data observability solution.{" "}
              </h3>
              <p>
                Data orchestration enables businesses to break down data silos,
                ensures business continuity, enforces regulatory compliance, and
                not only unifies data movements across organization but in an
                optimized and automated way. Ultimately enabling organization to
                focus on high-value tasks.
              </p>
              <ul class="list-w mt-4">
                <li>
                  Data quality is your priority, or your data platform has data
                  quality issues.
                </li>
                <li>
                  Your organization is integrating data from legacy system with
                  modern data stacks
                </li>
                <li>Your data volumes continue to grow exponentially</li>
                <li>
                  Your data stack is scaling with more data sources with
                  interdependencies, and more complexity. (Lots of ETL jobs and
                  complex data ecosystems)
                </li>
                <li>
                  Your team is spending at least 30% of their time firefighting
                  data related failures.
                </li>
                <li>Data is a key part of business decision making.</li>
              </ul>
            </div>
          </div>
        </section>

        <Footer></Footer>
      </div>
    </div>
  );
}

export default Orchestration;
