import React from "react";
import Header from "./../layout/header";
import Footer from "./../layout/footer";

function About() {
  return (
    <div>
      <div className="site-wrapper">
        <div className="first_nav_hero_about">
          <Header></Header>
          {/* ======== 2.1. contact-hero section ========  */}
          <div className="container">
            <div className="contact-hero">
              <h2 className="text-center">CONTACT</h2>
              <p className="text-center">
                Whether you have a question about our services, trials, pricing
                or anything else, our team is ready to answer all your
                questions!
              </p>
            </div>
          </div>
          <div className="map">
            <iframe
              title="Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96748.5656858991!2d-74.26445728824706!3d40.73138423906509!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25370329a0e1d%3A0xe1bcdc2adcfee473!2sNewark%2C%20NJ%2C%20USA!5e0!3m2!1sen!2s!4v1691826891864!5m2!1sen!2s"
              width="600"
              height="450"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          {/* ======== End of 2.1. contact-hero section ========  */}
        </div>

        <div className="d-flex justify-content-center">
          <div className="help position-relative">
            <div className="container">
              <div className="row d-flex gap-lg-5 gap-md-3 gap-sm-4 gap-3 justify-content-center">
                <div className="col-lg-5 col-md-5 help-crd1">
                  <h4>HOW CAN WE HELP?</h4>
                  <p>get it touch to know more</p>
                  <div className="d-flex gap-4 align-items-center">
                    <i className="fa-solid fa-house"></i>
                    <span>30 N Gould St Ste N, Sheridan, WY 82801</span>
                  </div>
                  <div className="d-flex gap-4 align-items-center">
                    <i className="fa-solid fa-phone"></i>
                    <span>(219) 214-6890</span>
                  </div>
                  <div className="d-flex gap-4 align-items-center">
                    <i className="fa-solid fa-envelope"></i>
                    <span>info@intelligo.com</span>
                  </div>
                  <h5>OPERATING HOURS</h5>
                  <div className="d-flex gap-4 align-items-center">
                    <i className="fa-solid fa-clock"></i>
                    <span>
                      Monday To Friday <br /> 8:00am to 8:00pm AEDT
                    </span>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 contact-email">
                  <h4>EMAIL US</h4>

                  <div className="row justify-content-center gap-3">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="col-md-5 col-sm-12 col-12"
                      placeholder="Your Name"
                      required
                    />
                    <input
                      type="text"
                      name="email"
                      id="email"
                      className="col-md-5 col-sm-12 col-12"
                      placeholder="Email Address"
                      required
                    />
                    <input
                      type="text"
                      name="number"
                      id="number"
                      className="col-md-5 col-sm-12 col-12"
                      placeholder="Phone Number"
                      required
                    />
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      className="col-md-5 col-sm-12 col-12"
                      placeholder="Subject"
                      required
                    />
                    <textarea
                      className="col-md-11 col-12"
                      name="massage"
                      id="massage"
                      cols="30"
                      rows="10"
                      placeholder="Write here message"
                    ></textarea>
                  </div>
                  <div className="d-flex justify-content-center mt-4">
                    <button className=" e-btn btn-hover1" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
                <div id="Succes-box"></div>
              </div>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    </div>
  );
}

export default About;
