import React from "react";
import { Link } from "react-router-dom";

import Header from "./../layout/header";
import Footer from "./../layout/footer";

import Slider from "react-slick";

function Home() {
  var settings = {
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 3000,
    slidesToShow: 4,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <div className="site-wrapper">
        <div className="first_nav_hero_about">
          <Header></Header>
          {/* ======== 1.2. hero section ======== */}
          <section className="hero">
            <div className="container">
              <div className="row text-md-start text-sm-center text-center gap-md-0 gap-sm-4 gap-5">
                <div
                  data-aos="fade-up"
                  className="col-md-6 d-flex align-items-md-start align-items-ms-center align-items-center justify-content-center flex-column"
                >
                  <h2>Accelerate data to insight journey</h2>
                  <p>
                    The most powerful and flexible Data Intelligence Platform
                    for your business. Data Intelligence platform and product
                    that puts your data to business insight journey at the
                    frontier.
                  </p>
                  <Link to="/intelligo" className="btn-hover1">
                    See it in action
                  </Link>
                </div>
                <div
                  data-aos="fade-down"
                  className="col-md-6 position-relative d-flex flex-column justify-content-center align-items-center mt-md-0 mt-sm-5 mt-4"
                >
                  <img
                    src="assets/images/index/hero.png"
                    alt="hero_img1"
                    className="moving"
                  />
                  <img
                    src="assets/images/index/hero_watch.png"
                    alt="hero_img2"
                  />
                  <img src="assets/images/icon/hero_star.png" alt="hero_icon" />
                </div>
              </div>
            </div>
          </section>
          {/* ======== End of 1.2. hero section ======== */}
        </div>

        {/* ======== 1.6. gateway section ========  */}
        <section className="gateway">
          <div className="container">
            <div className="row gap-lg-0 gap-md-0 gap-sm-4 gap-4">
              <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                <div data-aos="fade-up" className="gateway-bg-img mt-5">
                  <figure>
                    <img
                      src="assets/images/index/gateway-1.png"
                      alt="gate_img1"
                      className="moving"
                    />
                  </figure>
                </div>
              </div>
              <div
                data-aos="fade-down"
                className="col-lg-6 col-md-6 text-md-start text-sm-center text-center"
              >
                <h2>Easy Collaboration for better outcomes</h2>
                <p className="pt-lg-4 pt-md-3 pt-sm-2 pt-2">
                  Easy Collaboration for better outcomes Our framework expedites
                  data foundation work, and our people can augment your team's
                  knowledge to move the business work forward. This is as easy
                  as 1,2,3.
                </p>
                <div className="gate mt-md-3 mt-sm-0 mt-4 d-flex flex-md-row flex-sm-column flex-column align-items-center">
                  <figure className="d-flex align-items-center">
                    <img
                      src="assets/images/icon/gate-icon1.png"
                      alt="gate-img1"
                    />
                  </figure>
                  <div className="account-text ms-3">
                    <h5 className="pb-2">
                      Build data foundation with our on the go Intelligo
                      framework
                    </h5>
                    <p className="p-f-s">
                      We have develop modern data framework, by incorporating
                      years of research and our experience with modern data
                      stack, which pulls insights from data in a snap.
                    </p>
                  </div>
                </div>
                <div className="gate d-flex mt-4 flex-md-row flex-sm-column flex-column align-items-center">
                  <figure className="d-flex align-items-center">
                    <img
                      src="assets/images/icon/gate-icon2.png"
                      alt="gate-img2"
                    />
                  </figure>
                  <div className="ms-3">
                    <h5 className="pb-2">
                      Spark the inspiration with cross-team collaboration
                    </h5>
                    <p className="p-f-s">
                      Our framework can be a creative canvas for your teams to
                      apply their specific knowledge and deliver on your
                      analytics journey.
                    </p>
                  </div>
                </div>
                <div className="gate d-flex mt-4 flex-md-row flex-sm-column flex-column align-items-center">
                  <figure className="d-flex align-items-center">
                    <img
                      src="assets/images/icon/gate-icon3.png"
                      alt="gate-img3"
                    />
                  </figure>
                  <div className="ms-3">
                    <h5 className="pb-2">Deliver the business outcome </h5>
                    <p className="p-f-s">
                      Our research work, specific knowledge, and our talent pool
                      can be a sounding board for you and your team to deliver
                      business outcome with higher quality work and with less
                      effort.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ======== End of 1.6. gateway section ========  */}

        {/* ======== 1.4. finance section ========  */}
        <section className="finance">
          <div className="container text-center">
            <h2>UNLOCKING THE POWER OF Data Insight Platform</h2>
            <p className="mt-0">
              At the core of our platform is a flexible, unified approach to
              data ingestion, storage, and processing. Built on the principles
              of low code and powered by machine learning, it promises to
              streamline your processes and deliver results faster than ever
              before.
            </p>
            <div className="finanes-card row gap-md-0 gap-sm-4 gap-4">
              <div className="col-lg-4 col-md-4 d-flex justify-content-center pe-lg-3 pe-md-0 pe-sm-3 pe-3">
                <div className="fin-card" data-aos="flip-up">
                  <figure>
                    <img src="assets/images/icon/graphe.png" alt="praph" />
                  </figure>
                  <h4>All-in-One Platform</h4>
                  <p className="p-f-s">
                    Our platform provides a holistic approach to data
                    management, seamlessly integrating the collection,
                    transformation, analysis.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 d-flex justify-content-center pe-lg-3 pe-md-0 pe-sm-3 pe-3">
                <div className="fin-card" data-aos="flip-up">
                  <figure>
                    <img src="assets/images/icon/doller.png" alt="doller" />
                  </figure>
                  <h4>Cloud-agnostic capabilities</h4>
                  <p className="p-f-s">
                    With cloud-agnostic features, our platform ensures
                    compatibility across various cloud providers, giving users
                    the flexibility to utilize.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 d-flex justify-content-center">
                <div className="fin-card" data-aos="flip-up">
                  <figure>
                    <img src="assets/images/icon/arow.png" alt="arow" />
                  </figure>
                  <h4>Plug & Play Framework</h4>
                  <p className="p-f-s">
                    Experience unparalleled agility with our intuitive
                    plug-and-play framework. Designed for both no-code and
                    low-code environments
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ======== End of 1.4. finance section ========  */}

        {/* ======== 1.7. services section ========  */}
        <section className="services">
          <div className="container">
            <div className="row gap-md-0 gap-sm-4 gap-4">
              <div className="col-lg-6 col-md-6">
                <h2 className="text-lg-start text-md-start text-sm-center text-center">
                  Zero to any scale: Track ROI at every step
                </h2>
                <p className="text-lg-start text-md-start text-sm-center text-center mt-lg-4 mt-md-2 mt-sm-2 mt-2 pb-4">
                  Our data insight platform empowers you to track ROI
                  meticulously at every step, ensuring that your data
                  investments yield the highest possible returns. From zero to
                  any scale, we provide the foundation for data-driven success.
                </p>
                <div className="d-flex justify-content-center gap-lg-4 gap-md-3 gap-sm-2 gap-2">
                  <div className="offers">
                    <h5 className="mb-2">Scalable Architecture</h5>
                    <p className="p-f-s">
                      Seamlessly scale from small datasets to massive data lakes
                      without compromising performance or accuracy.
                    </p>
                  </div>
                  <div className="offers">
                    <h5 className="mb-2">Comprehensive ROI Tracking</h5>
                    <p className="p-f-s">
                      Monitor the ROI of your data initiatives at every stage,
                      from data collection to actionable insights.
                    </p>
                  </div>
                </div>
                <div className="serives-btn justify-content-md-start justify-content-ms-center justify-content-center d-flex">
                  <a className="btn-hover1" href="#">
                    Learn More
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center sevices_img">
                <div className="position-relative">
                  <figure>
                    <img
                      src="assets/images/index/lady-mobile.png"
                      alt="sevice_img2"
                    />
                  </figure>
                  <figure>
                    <img
                      src="assets/images/icon/whitStar.png"
                      alt="sevice_img3"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ======== End of 1.7. services section ========  */}

        {/* ======== 1.5. ispsum section ========  */}
        <div className="ispsum-logo">
          <div className="container">
            <hr />
            <div className="logo_ispsum_slider">
              <Slider {...settings}>
                <a href="#">
                  <figure>
                    <img
                      src="assets/images/icon/Snowflake_Logo.png"
                      alt="img"
                    />
                  </figure>
                </a>
                <a href="#">
                  <figure>
                    <img
                      src="assets/images/icon/databricks_logo.png"
                      alt="img"
                    />
                  </figure>
                </a>
                <a href="#">
                  <figure>
                    <img src="assets/images/icon/AWS_logo.png" alt="img" />
                  </figure>
                </a>
                <a href="#">
                  <figure>
                    <img
                      src="assets/images/icon/Microsoft_Azure_Logo.png"
                      alt="img"
                    />
                  </figure>
                </a>
                <a href="#">
                  <figure>
                    <img
                      src="assets/images/icon/Google_Cloud_Logo.png"
                      alt="img"
                    />
                  </figure>
                </a>
              </Slider>
            </div>
            <hr />
          </div>
        </div>
        {/* ======== End of 1.5. ispsum section ========  */}

        <Footer></Footer>
      </div>
    </div>
  );
}

export default Home;
