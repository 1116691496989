import React from "react";
import Header from "./../../layout/header";
import Footer from "./../../layout/footer";

function Governance() {
  return (
    <div>
      <div className="site-wrapper">
        <div className="first_nav_hero_about">
          <Header></Header>
          {/* ======== 1.2. hero section ======== */}
          <section className="core">
            <div className="container">
              <h2 className="text-center">Governance</h2>
              <p className="core-p">The art of understanding data health.</p>
            </div>
          </section>
          {/* ======== End of 1.2. hero section ======== */}
        </div>

        <section className="services">
          <div className="container">
            <div class="row  justify-content-center mb-5">
              <div
                data-aos="fade-up"
                class="col-lg-6 col-md-6 d-flex align-items-center justify-content-center aos-init aos-animate"
              >
                <div class=" gateway-bg-img mt-5 ">
                  <figure>
                    <img src="assets/images/index/pricinge.png" alt="img" />
                  </figure>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 justify-content-center">
                <h3>What is Data Governance:?</h3>
                <p>
                  Gartner defines data governance as a way to “specify decision
                  rights and accountability to ensure appropriate behavior as
                  organizations seek to value, create, consume, and control
                  their data, analytics, and information assets.”
                </p>
              </div>
            </div>

            <div class="fin-card aos-init mb-4">
              <h3 class="mb-4">What business value does it bring?</h3>
              <ul class="list-w mt-4">
                <li>
                  Drive data literacy: By providing centralized, cross-team
                  collaboration platform for organization users to create a
                  shared understanding of data across systems. This shared
                  understanding can then help define standards and definitions
                  of these assets to fuel adoption through appropriate access.
                </li>
                <li>
                  Build trust in your data at scale and speed: Data catalog,
                  data lineage solutions of data governance ensure data
                  integrity, accuracy and completeness.
                </li>
                <li>
                  Promote advanced analytics: Shared understanding of data and
                  high trust in data lay the foundation for more advanced data
                  analytics and data science initiatives within the
                  organization.
                </li>
                <li>
                  Ensure regulatory compliance: Data governance policies provide
                  a way to meet the regulatory requirements and tool to set
                  guardrails to prevent misuse of data.
                </li>
              </ul>
            </div>

            <div class="fin-card aos-init mb-4">
              <h3 class="mb-4">
                Should you buy it? Signs you need a data governance solution.
              </h3>
              <p>
                Data governance capabilities enable organizations to build trust
                in data and cross-team collaboration with privacy. Ultimately
                enabling the organization to become a data-driven organization.
              </p>
              <ul class="list-w mt-4">
                <li>
                  Organizational alignment on data assets and their shared
                  definitions is one of your top priorities.
                </li>
                <li>
                  Creating a knowledge catalog of data assets across
                  organization is one of your top priorities.
                </li>
                <li>
                  Instill organizational trust in your data is one of your top
                  priorities.
                </li>
                <li>
                  Your team is spending at least 20% of their time tracing the
                  error in data through its lifecycle.
                </li>
                <li>Data is a key part of business decision making.</li>
              </ul>
            </div>

            <div class="fin-card aos-init mb-4">
              <h3 class="mb-4">
                What are the limitations we have seen in our competitors’
                offerings?
              </h3>
              <ul class="list-w mt-4">
                <li>
                  Their solutions fall short on the promise of self-service tool
                  and most of the implementation and maintenance steps require
                  manual intervention.
                </li>
                <li>
                  Their governance policies and implementations are rigid and
                  support command -control model of metadata management Vs ours
                  is persona-based adaptive governance implementation which
                  enables us to provide customized persona-based experience.
                </li>
                <li>
                  Not enough metadata to improve your MTTR (Mean Time To
                  Recover) when your data pipelines/jobs fail.
                </li>
              </ul>
            </div>

            <div class="fin-card aos-init mb-4">
              <h3 class="mb-4">What are our data Governance offerings?</h3>
              <p>Our solution Governs:</p>
              <ul class="list-w mt-4">
                <li>Adaptive governance practices Vs command & control:</li>
                <li>
                  Cross-team collaboration to create persona-based business
                  glossary and catalog.
                </li>
                <li>
                  Flexible and extensible framework which supports every edge
                  case
                </li>
                <li>
                  Data lineage which traces data in its lifecycle and from
                  source to analytics use cases (End to end column-level data
                  lineage)
                </li>
                <li>
                  Auto-tagging and classification, Role-based and Policy-based
                  access controls.
                </li>
              </ul>
            </div>
          </div>
        </section>

        <Footer></Footer>
      </div>
    </div>
  );
}

export default Governance;
