import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div>
      {/* ======== 1.13. footer section ========  */}
      <footer className="position-relative">
        <div className="container">
          <div className="footer-logo text-center pb-lg-4 pb-md-3 pb-sm-2 pb-4">
            {/* footer logo  */}
            <Link to="/">
              <figure>
                <img src="assets/images/Logo.png" alt="img" />
              </figure>
            </Link>
          </div>
          <ul className="d-flex align-items-center justify-content-center">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/intelligo">Intelligo</Link>
            </li>
            <li>
              <Link to="/resources">Resources</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
          </ul>
          <hr />
          <div className="row footer-nav-icon">
            {/* footer social icon  */}
            <div className="col-lg-3 col-md-3 d-flex align-items-center justify-content-md-start justify-content-sm-center justify-content-center">
              <div className="social-icon d-flex gap-2 justify-content-md-start justify-content-sm-center justify-content-center">
                <Link to="/">
                  <i className="fa-brands fa-facebook-f foot-facebook"></i>
                </Link>
                <Link to="/">
                  {" "}
                  <i className="fa-brands fa-twitter"></i>
                </Link>
                <Link to="/">
                  {" "}
                  <i className="fa-brands fa-instagram"></i>
                </Link>
              </div>
            </div>
            {/* footer terms privacy  */}
            <div className="col-lg-6 col-md-6 d-flex justify-content-center align-items-center">
              <div className="d-flex gap-3 p-2">
                <Link to="/">Terms & Condition</Link>
                <Link to="/">Privacy Policy</Link>
              </div>
            </div>
          </div>
          <hr />
          <div className="Copyright d-flex justify-content-between flex-wrap dir">
            <p>
              Copyright © 2021 -{currentYear} © Intelligo. All Right Reserved.
            </p>
            <p>Powered by Intelligo</p>
          </div>
        </div>
      </footer>
      {/* ======== End of 1.13. footer section ========  */}
    </div>
  );
}

export default Footer;
