import React from "react";
import Header from "./../../layout/header";
import Footer from "./../../layout/footer";

function Transformation() {
  return (
    <div>
      <div className="site-wrapper">
        <div className="first_nav_hero_about">
          <Header></Header>
          {/* ======== 1.2. hero section ======== */}
          <section className="core">
            <div className="container">
              <h2 className="text-center">Transformation</h2>
              <p className="core-p">
                The art of tweaking data for great analytics.
              </p>
            </div>
          </section>
          {/* ======== End of 1.2. hero section ======== */}
        </div>

        {/*  ======== 1.7. services section ========  */}
        <section className="services">
          <div className="container">
            <div class="row  justify-content-center mb-5">
              <div
                data-aos="fade-up"
                class="col-lg-6 col-md-6 d-flex align-items-center justify-content-center aos-init aos-animate"
              >
                <div class=" gateway-bg-img mt-5 ">
                  <figure>
                    <img src="assets/images/index/pricinge.png" alt="img" />
                  </figure>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 justify-content-center">
                <p>
                  Are you having trouble understanding the complex data
                  ecosystem? It can be difficult to keep up-to-date in such a
                  fast-moving field. Vendor-driven educational content
                  attempting to market its product can be exhausting! Data
                  engineering is a broad field, you are expected to know about
                  systems generating the data, processing the data, modeling the
                  data, and finally serving the data to the end-users.
                </p>
                <p>
                  While all this may feel overwhelming, it doesn't have to be.
                </p>
                <p>
                  What if you understood the core data engineering concepts?
                  You'll be able to figure out how any tool fits into the data
                  ecosystem. You will also be able to design scalable,
                  resilient, and easy-to-use/maintain data pipelines.
                </p>
              </div>
            </div>

            <div class="fin-card aos-init mb-4">
              <h3 class="mb-4">What is Data Transformation? </h3>
              <p>
                Data transformation is the process of modifying data to make it
                more usable and analyzable for business purposes. It can involve
                changing the data's structure, format, or values, and can
                include tasks such as cleaning, standardization, or aggregation.
                The goal is to prepare the data for further processing and
                analysis, and to integrate diverse datasets into a unified
                format. This can help businesses make informed decisions and
                drive organizational growth.
              </p>
              <p>
                The ability to turn raw data into business data models and
                automate your entire data integration process with our advanced
                transformation workflows or our pre-built data model kits.
              </p>
              <p>
                The ability to understand the health of organization’s data at
                rest, data in motion, data usage and data infrastructure by
                continuously monitoring, tracking, alerting, analyzing,
                troubleshooting, and recommending the corrective actions to
                prevent firefighting.
              </p>
              <p>
                Observability implemented correctly aims to improve reliability
                of modern data ecosystems.
              </p>
            </div>

            <div class="fin-card aos-init mb-4">
              <h3 class="mb-4">What business value it brings?</h3>
              <ul class="list-w mt-4">
                <li>
                  Faster time to value by accelerating your data to outcome
                  journey
                </li>
                <li>
                  Reduction in your data-related cost by Simplifying your data
                  stack
                </li>
                <li>
                  Easy to use for non-engineers. Our analytical framework
                  empowers your data analyst empowers data analysts to write
                  code that is version-controlled, thoroughly tested,
                  documented, and is easy to collaborate on.
                </li>
              </ul>
            </div>

            <div class="fin-card aos-init mb-4">
              <h3 class="mb-4">
                Should you buy it? Signs you need a data observability solution.
              </h3>
              <ul class="list-w mt-4">
                <li>Your data platform has recently migrated to cloud.</li>
                <li>
                  Your data stack is scaling with more data sources, more
                  tables, and more complexity. (Lots of ETL jobs and complex
                  data ecosystems)
                </li>
                <li>
                  Your data team and your stakeholders in how data is transform
                  are growing.
                </li>
                <li>
                  Your team is spending at least 30% of their time firefighting
                  data quality issues.
                </li>
                <li>
                  Your team has more data consumers than you did 1 year ago.
                </li>
                <li>
                  Your company is moving to self-service analytics model yet
                  wants to enforce standards on your software development
                  processes.
                </li>
                <li>Data is a key part of business decision making.</li>
              </ul>
            </div>

            <div class="fin-card aos-init mb-4">
              <h3 class="mb-4">
                Should you buy it? Signs you need a data observability solution.
              </h3>
              <ul class="list-w mt-4">
                <li>
                  Transform part of ELT/ETL becomes a black-box for your
                  organization
                </li>
                <li>Non-engineers</li>
                <li>Target Tech only and Fall shorts on End users</li>
                <li> No visibility on Cost and Financial impacts</li>
              </ul>
            </div>

            <div class="fin-card aos-init mb-4">
              <h3 class="mb-4">What are our Observability offerings?</h3>
              <ul class="list-w mt-4">
                <li>
                  Our solution observes and recommends: 1. Data quality issues
                  with completeness, accuracy, timeliness, anomalies, uniqueness
                </li>
                <li>
                  Changes in Schema, volume, performance, frequency, time while
                  data-in-motion
                </li>
                <li>
                  Logs and metrics around performance, compute, storage, and
                  network
                </li>
                <li>
                  Changes in metadata of ownership, usage with who, when, why,
                  and how often.
                </li>
                <li>
                  Financial impact with downtime, SLA, resource, and capacity
                  planning.
                </li>
              </ul>
            </div>
          </div>
        </section>
        {/*  ======== End of 1.7. services section ========  */}

        <Footer></Footer>
      </div>
    </div>
  );
}

export default Transformation;
